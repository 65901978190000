import React from 'react'

import Layout from '../../components/Layout'

const services = [
  {
    title: (
      <span>
        Virtual Yoga - Intro <span className='has-text-dancing-script'> 3</span>{' '}
        Week Pass
      </span>
    ),
    subtitle: 'Expiration date 3 weeks from first use.',
    price: '$30.00',
    link:
      'https://checkout.square.site/merchant/548HVAMT5X4F2/checkout/KT4FCZWT73RFFQUFFPCJNBPF',
  },
  {
    title: (
      <span>
        Virtual Yoga - <span className='has-text-dancing-script'>8</span> Class
        Pass
      </span>
    ),
    subtitle: 'Expiration date 12 weeks from first use.',
    price: '$108.00',
    link:
      'https://checkout.square.site/merchant/548HVAMT5X4F2/checkout/WGMFVTH5TD5KJQLGQ2NMBUF3',
  },
  {
    title: 'Yoga - In Person - 60 Minute Session',
    price: '$79.00',
    link:
      'https://checkout.square.site/merchant/548HVAMT5X4F2/checkout/D2VRG5A2DZLSM24TAGZLJOVN',
  },
  {
    title: 'Bodywork - 60 Minute Session',
    price: '$95.00',
    link:
      'https://checkout.square.site/merchant/548HVAMT5X4F2/checkout/YVWFDVTYJTOT4JIS43L6O5PR',
  },
  {
    title: 'Bodywork - 75 Minute Session',
    price: '$120.00',
    link:
      'https://checkout.square.site/merchant/548HVAMT5X4F2/checkout/HYVV26QDIKGTSQN4F72J4OBT',
  },
  {
    title: 'Bodywork - 90 Minute Session',
    price: '$135.00',
    link:
      'https://checkout.square.site/merchant/548HVAMT5X4F2/checkout/HEGCQAUAOGC464SHPJT56PKP',
  },
]

const PaymentsPage = () => {
  return (
    <Layout>
      <section id='hero' className='hero is-dark-brown'>
        <div className='hero-body'>
          <div className='container'>
            <div className='section'>
              <h1 className='title is-1 has-text-white'>Payments</h1>
            </div>
          </div>
        </div>
      </section>
      <section className='section payments-message'>
        <div className='container is-max-desktop'>
          <p>
            Please use the pay buttons below to pay for services provided. They
            will open a new tab and bring you to a secure payment form provided
            by Square.
          </p>
        </div>
      </section>
      <section className='section'>
        <div className='container is-max-desktop'>
          <div className='payments-container'>
            {services.map(({title, subtitle, price, link}, idx) => (
              <div className='box is-flex is-flex-direction-column' key={idx}>
                <h3 className='title is-3 is-2-mobile has-text-white'>
                  {title}
                </h3>
                {subtitle && <p className='has-text-info-light'>{subtitle}</p>}
                <br />
                <p className='mb-5'>{price}</p>
                <a
                  className='theme-btn'
                  href={link}
                  target='_blank'
                  rel='noreferrer'
                >
                  Pay for Service
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PaymentsPage
